<template>
  <v-dialog
    v-model="dialog"
    @keydown.esc="cancel"
    transition="dialog-bottom-transition"
    hide-overlay
    fullscreen
  >
    <v-card>
      <v-toolbar dark :color="options.color">
        <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="agree" v-if="options.save !== undefined">
            {{ options.save }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AppDialogFullScreen',
  props: {
    isValid: Boolean
  },
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200
    }
  }),
  methods: {
    open(title, options) {
      this.dialog = true
      this.title = title
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      if (this.isValid) {
        this.resolve(true)
        this.dialog = false
      }
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>
